<template>
    <div class="relative w-full">
        <div class="absolute w-full grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6"
             v-if="allWords.length > 0" style="z-index: -1;">
            <div ref="cardSize" class="w-full opacity-0 pointer-events-none invisible">
                <WordCard @mounted="setItemSize"
                          :word="allWords[0]" />
            </div>
        </div>
    </div>

    <RecycleScroller
        :items="words"
        class="w-full pt-12 flex-1 virtual-scroller"
        :item-size="itemHeight"
        :item-secondary-size="itemWidth"
        ref="scroller"
        key-field="keyForScroller"
        :gridItems="gridItems"
        :buffer="500"
        typeField="typeForScroller"
        v-slot="{ item }"
    >
        <!-- This is some hacky stuff to render the title but still use scroller feature, we need to check if this item is the title or a word-->
        <div v-if="item.typeForScroller === 'title'" class="w-full h-full flex flex-col justify-center items-start">
            <!-- Just render the first one, still push 4 so gridItems work with scroller  -->
            <h2 class="text-white" v-if="item.index === 0">
                {{ $t(`global.difficulty.${item.difficulty}`) }}
            </h2>
        </div>
        <div class="w-full h-full" style="padding-right: 18px;" v-else>
            <WordCard :word="item.word" />
        </div>
    </RecycleScroller>
</template>

<script>
import WordCard from "components/cards/WordCard"
import { DIFFICULTY } from "@globals/constants"
import { RecycleScroller } from "vue-virtual-scroller"
import throttle from "lodash-es/throttle"

export default {
    name: "WordsByDfficulty",
    components: { RecycleScroller, WordCard },
    props: {
        wordGroup: Object
    },
    data() {
        return {
            DIFFICULTY,

            wordsByDifficulty: [],
            words: [],
            itemWidth: 20,
            itemHeight: 20,
            gridItems: 4
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize, { passive: true })
        this.setItemSize()
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResize)
    },
    watch: {
        async wordGroup() {
            await this.$nextTick()
            this.setWords()
            this.setItemSize()
        },
        "allWords.length": {
            handler: function() {
                this.$nextTick(() => {
                    this.setWords()
                    this.setItemSize()
                })
            },
            immediate: true
        }
    },
    computed: {
        allWords() {
            if (this.$store.getters.isUserLogin) {
                return this.$store.state.userData.words
            }
            if (this.$store.getters.isStudentLogin) {
                return this.$store.state.studentData.words
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.state.anonymousData.words
            }
            return []
        }
    },
    methods: {
        onResize: throttle(function() {
            this.setItemSize()
        }, 250, {
            leading: true,
            trailing: true
        }),
        setItemSize() {
            /*xs: "512px",
                sm: "640px",
                md: "768px",
                lg: "1024px",
                xl: "1280px",
                "2xl": "1536px",
                "3xl": "1920px" */
            //grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 mutch match that
            if (window.innerWidth < 512) {
                this.gridItems = 1
            } else if (window.innerWidth < 640) {
                this.gridItems = 2
            } else if (window.innerWidth < 1024) {
                this.gridItems = 3
            } else {
                this.gridItems = 4
            }

            if (this.$refs.cardSize && this.$refs.cardSize.clientHeight > 0) {
                this.itemHeight = this.$refs.cardSize.clientHeight + 18//18px padding but 1px issue
                this.itemWidth = this.$refs.cardSize.clientWidth + 17//18px padding but 1px issue
            }

            //filter out the titles by gridItems
            this.words = this.wordsByDifficulty.filter(w => {
                if (w.typeForScroller === "title") {
                    if (w.index >= this.gridItems) {
                        return false
                    }
                }
                return true
            })
            this.$refs.scroller.updateVisibleItems(true)
        },
        setWords() {
            const wordIds = this.wordGroup ? this.wordGroup.wordIds : []

            const result = {
               //start empty so we know what difficulty contain word
            }

            let finalArray = []
            for (const word of this.allWords) {
                if (!wordIds.includes(word.id)) {
                    continue
                }
                //init
                if (typeof result[word.difficulty] === "undefined") {
                    result[word.difficulty] = []
                }
                result[word.difficulty].push({
                    word: word,
                    keyForScroller: word.id,
                    typeForScroller: "word"
                })
            }

            //if just 1 difficulty, no need to show title so just return the array
            if (Object.keys(result).length === 1){
                this.wordsByDifficulty = result[Object.keys(result)[0]]
                return
            }

            for (const key in result) {
                if (result[key].length === 0) {
                    continue
                }
                finalArray = finalArray.concat(
                    Array.from({ length: 4 }).map((_, idx) => ({
                        keyForScroller: `title-${idx}`,
                        typeForScroller: "title",
                        difficulty: key,
                        index: idx
                    }))
                )

                finalArray = finalArray.concat(result[key])
            }
            this.wordsByDifficulty = finalArray
        }
    }
}
</script>
