<template>
    <StudentHeader class="mb-6">
        <h1 class="text-white">
            {{ $t("list.title") }}
        </h1>
    </StudentHeader>


    <div class="relative w-full">
        <div ref="cardSize" class="w-full absolute" style="z-index: -1;">
            <div class="w-full h-full" v-if="sortedWordGroups.length > 0">
                <WordGroupCard
                    @mounted="setItemSize"
                    class="w-full opacity-0 pointer-events-none invisible"
                    :word-group="sortedWordGroups[0].wordGroup"
                />
            </div>
        </div>
    </div>
    <RecycleScroller
        :items="sortedWordGroups"
        class="w-full flex-1 virtual-scroller"
        :item-size="itemSize"
        ref="scroller"
        key-field="keyForScroller"
        type-field="typeForScroller"
        v-slot="{ item }"
    >
        <div class="w-full h-full">
            <WordGroupCard
                class="cursor-pointer"
                :word-group="item.wordGroup"
                @click.prevent="goToWordGroup(item.wordGroup.uuid)" />
        </div>
    </RecycleScroller>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import WordGroupCard from "components/cards/WordGroupCard"
import { RecycleScroller } from "vue-virtual-scroller"
import throttle from "lodash-es/throttle"

export default {
    name: "List",
    components: { RecycleScroller, WordGroupCard, StudentHeader },
    data() {
        return {
            itemSize: 120//default
        }
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUPS)
    },
    mounted() {
        window.addEventListener("resize", this.onResize, { passive: true })
        this.setItemSize()
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.onResize)
    },
    computed: {
        sortedWordGroups() {
            return this.$store.getters.sortedWordGroups.map(wG => ({
                wordGroup: wG,
                keyForScroller: wG.id,
                typeForScroller: "wordGroup"
            }))
        }
    },
    methods: {
        onResize: throttle(function() {
            this.setItemSize()
        }, 250, {
            leading: true,
            trailing: true
        }),
        setItemSize() {
            if (this.$refs.cardSize && this.$refs.cardSize.clientHeight > 0) {
                this.itemSize = this.$refs.cardSize.clientHeight + 18//18px padding
            }

            this.$refs.scroller.updateVisibleItems(true)
        },
        goToWordGroup(uuid) {
            this.$router.push(`/student/list/${uuid}`)
        }
    }
}
</script>
