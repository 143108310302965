<template>
    <UserHeader class="mb-6">
        <h1>
            {{ $t("students.title") }}
        </h1>
        <AddButton
            @click.prevent="createStudent"
            v-if="selectedClassroom" />
    </UserHeader>

    <div class="md:flex flex-row flex-wrap justify-start items-stretch w-full flex-1 pb-6 md:h-0">
        <div class="w-full md:w-1/3 md:pr-3 max-h-full">
            <Card class="h-full">
                <simplebar class="scroll-container h-full max-h-full">
                    <div class="px-6">
                        <div class="flex flex-row justify-between items-center">
                            <h2 v-if="$store.state.auth.user.isTeacher">
                                {{ $t("students.myClassrooms") }}
                            </h2>
                            <h2 v-if="$store.state.auth.user.isParent">
                                {{ $t("students.myGroups") }}
                            </h2>

                            <button
                                type="button"
                                class="appearance-none"
                                @click.prevent="createClassroom"
                                v-if="$store.state.auth.user.isTeacher">
                                <Popper
                                    class="w-full h-full block"
                                    :content="$t(`form.createClassroom`)">
                                    <Icons
                                        icon="plus"
                                        class="w-7 h-7 block text-purple" />
                                </Popper>
                            </button>
                        </div>

                        <p
                            v-if="classrooms.length === 0 && !selectedClassroom"
                            class="py-4 text-purple -small">
                            {{ $t("students.noClassroom") }}
                        </p>

                        <div class="grid grid-cols-1 gap-3 mt-6 pb-6">
                            <div
                                v-for="classroom in classrooms"
                                :key="classroom.id"
                                class="rounded py-4 px-2 bg-white text-left font-medium flex flex-row justify-between items-center"
                                @click.prevent="selectClassroom(classroom)"
                                :class="{
                                    'bg-gray-100': selectedClassroom && selectedClassroom.uuid === classroom.uuid,
                                    'cursor-pointer': classroom.uuid !== familyGroup.uuid
                                }">
                                <div>
                                    <h3 class="text-left mb-1">
                                        {{ classroom.title }}
                                    </h3>
                                    <p
                                        class="-smallest font-medium text-purple"
                                        v-if="classroom.listGroupId && getListGroupName(classroom)">
                                        {{ $t("students.listGroup") }}: {{ getListGroupName(classroom) }}
                                    </p>
                                    <p
                                        class="-smallest font-medium text-red"
                                        v-else-if="classroom.uuid !== familyGroup.uuid">
                                        {{ $t("students.noListGroup") }}
                                    </p>
                                </div>
                                <div
                                    class="flex flex-row justify-end space-x-3 items-center ml-1"
                                    v-if="classroom.id">
                                    <button
                                        class="appearance-none"
                                        @click.prevent="goToClassroomPage(classroom)"
                                        v-if="$store.state.auth.user.isTeacher">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`global.help.progress`)">
                                            <Icons
                                                icon="progress"
                                                class="w-6 h-6 text-purple block" />
                                        </Popper>
                                    </button>

                                    <button
                                        type="button"
                                        @click.prevent="editClassroom(classroom)">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`form.btnEdit`)">
                                            <Icons
                                                icon="edit"
                                                class="w-6 h-6 text-purple block" />
                                        </Popper>
                                    </button>
                                    <button
                                        type="button"
                                        class="appearance-none"
                                        @click.prevent="deleteClassroom(classroom)">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`form.btnDelete`)">
                                            <Icons
                                                icon="trash"
                                                class="w-6 h-6 text-purple block" />
                                        </Popper>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </simplebar>
            </Card>
        </div>
        <div class="w-full md:w-2/3 md:pl-3 pt-6 md:pt-0 max-h-full">
            <Card class="h-full">
                <simplebar class="scroll-container h-full max-h-full">
                    <div class="px-6">
                        <div class="flex flex-row justify-between items-center mb-6">
                            <h3
                                class="font-medium"
                                v-if="selectedClassroom">
                                {{ selectedClassroom.title }}
                                <span v-if="$store.state.auth.user.isTeacher">({{ selectedClassroom.code }})</span>
                            </h3>
                            <div>
                                <div class="flex flex-row justify-between items-center space-x-2">
                                    <button
                                        type="button"
                                        class="appearance-none"
                                        @click.prevent="showBulkDocument"
                                        v-if="
                                            $store.state.auth.user.isTeacher && selectedClassroom && students.length > 0
                                        ">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`students.seeThePdf`)">
                                            <Icons
                                                icon="doc"
                                                class="w-6 h-6 block text-purple" />
                                        </Popper>
                                    </button>
                                    <button
                                        type="button"
                                        class="appearance-none"
                                        @click.prevent="createStudent"
                                        v-if="selectedClassroom">
                                        <Popper
                                            class="w-full h-full block"
                                            :content="$t(`form.createStudent`)">
                                            <Icons
                                                icon="plus"
                                                class="w-7 h-7 block text-purple" />
                                        </Popper>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="my-4"
                            v-if="students.length === 0 && selectedClassroom">
                            <p class="text-purple -small">
                                <template v-if="$store.state.auth.user.isParent">
                                    {{ $t("students.noStudentsFromParent") }}
                                </template>
                                <template v-if="$store.state.auth.user.isTeacher">
                                    {{ $t("students.noStudentsFromTeacher") }}
                                </template>
                            </p>
                        </div>
                        <div
                            class="w-full flex flex-row justify-start items-center space-x-1 py-2 border-b border-purple-10"
                            v-if="students.length > 0">
                            <div class="w-2/6">
                                <p class="-smallest font-medium text-purple">
                                    {{ $t("students.name") }}
                                </p>
                            </div>
                            <div class="w-1/6">
                                <p class="-smallest font-medium text-purple">
                                    {{ $t("students.isConnected") }}
                                </p>
                            </div>
                            <div class="w-1/6">
                                <p class="-smallest font-medium text-purple">
                                    {{ $t("students.difficulty") }}
                                </p>
                            </div>
                            <div class="w-2/6"></div>
                        </div>
                        <div
                            class="flex flex-row justify-start items-center w-full py-2 space-x-1"
                            v-for="(student, idx) in students"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }"
                            :key="student.id">
                            <div class="w-2/6 flex flex-row justify-start items-center space-x-2">
                                <StudentAvatar
                                    with-popper
                                    :student="student"
                                    size="small" />
                                <div>
                                    <p class="-small text-purple">
                                        {{ student.name }}
                                    </p>
                                    <p class="text-purple -smallest">({{ student.username }})</p>
                                </div>
                            </div>
                            <div class="w-1/6">
                                <span
                                    :class="`${student.isConnected ? 'bg-green' : 'bg-red'}`"
                                    class="block w-4 min-w-4 h-4 rounded-full shadow" />
                            </div>
                            <div class="w-1/6">
                                <p class="-small text-purple">
                                    {{ $t(`global.difficulty.${student.difficulty}`) }}
                                </p>
                            </div>
                            <div class="w-2/6 flex flex-row justify-end items-center space-x-3">
                                <button
                                    class="appearance-none"
                                    @click.prevent="goToStudentPage(student)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`global.help.progress`)">
                                        <Icons
                                            icon="progress"
                                            class="w-6 h-6 text-purple block" />
                                    </Popper>
                                </button>

                                <button
                                    class="appearance-none"
                                    @click.prevent="editStudent(student)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.btnEdit`)">
                                        <Icons
                                            icon="edit"
                                            class="w-6 h-6 text-purple block" />
                                    </Popper>
                                </button>

                                <button
                                    class="appearance-none"
                                    @click.prevent="deleteStudent(student)">
                                    <Popper
                                        class="w-full h-full block"
                                        :content="$t(`form.btnDelete`)">
                                        <Icons
                                            icon="trash"
                                            class="w-6 h-6 text-purple block" />
                                    </Popper>
                                </button>
                            </div>
                        </div>
                    </div>
                </simplebar>
            </Card>
        </div>
    </div>

    <DeleteItemModal
        :item-title="selectedClassroom && selectedClassroom.title"
        :is-loading="isDeletingClassroom"
        v-if="showDeleteClassroomModal"
        @delete="confirmDeleteClassroom"
        @close="showDeleteClassroomModal = false">
        <p class="text-red -small mt-4 mb-12 text-center">
            {{ $t("modal.deleteItem.deletingClassroomDescription") }}
        </p>
    </DeleteItemModal>

    <CreateClassroomModal
        @created="onCreatedClassroom"
        @close="showCreateClassroomModal = false"
        v-if="showCreateClassroomModal" />
    <EditClassroomModal
        @close="showEditClassroomModal = false"
        v-if="showEditClassroomModal"
        :classroom="selectedClassroom" />

    <DeleteItemModal
        :item-title="selectedStudent && selectedStudent.name"
        :is-loading="isDeletingStudent"
        v-if="showDeleteStudentModal"
        @delete="confirmDeleteStudent"
        @close="showDeleteStudentModal = false">
        <p class="text-red -small mt-4 mb-12 text-center">
            {{ $t("modal.deleteItem.deletingStudentDescription") }}
        </p>
    </DeleteItemModal>
    <CreateStudentModal
        @close="showCreateStudentModal = false"
        v-if="showCreateStudentModal"
        :classroom-id="selectedClassroom && selectedClassroom.id ? selectedClassroom.id : null" />

    <EditStudentModal
        @close="showEditStudentModal = false"
        v-if="showEditStudentModal"
        :student="selectedStudent"
        :classroom-id="selectedClassroom && selectedClassroom.id ? selectedClassroom.id : null" />

    <ShowClassroomBulkDocument
        @close="showClassroomBulkDocumentModal = false"
        v-if="showClassroomBulkDocumentModal"
        :classroom="selectedClassroom" />
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import UserHeader from "components/globals/UserHeader"
import AddButton from "components/globals/AddButton"
import { v4 } from "uuid"
import CreateStudentModal from "components/modals/user/CreateStudentModal"
import StudentAvatar from "components/globals/StudentAvatar"
import Icons from "components/globals/Icons"
import CreateClassroomModal from "components/modals/user/CreateClassroomModal"
import EditClassroomModal from "components/modals/user/EditClassroomModal"
import Simplebar from "components/globals/Simplebar"
import EditStudentModal from "components/modals/user/EditStudentModal"
import Popper from "components/globals/Popper"
import ShowClassroomBulkDocument from "components/modals/user/ShowClassroomBulkDocument"
import DeleteItemModal from "components/modals/user/DeleteItemModal"

export default {
    name: "UserStudents",
    components: {
        DeleteItemModal,
        ShowClassroomBulkDocument,
        Popper,
        EditStudentModal,
        Simplebar,
        EditClassroomModal,
        CreateClassroomModal,
        Icons,
        StudentAvatar,
        CreateStudentModal,
        AddButton,
        UserHeader,
        Card
    },
    data() {
        return {
            showCreateStudentModal: false,
            showEditStudentModal: false,
            showDeleteStudentModal: false,
            isDeletingStudent: false,

            showClassroomBulkDocumentModal: false,
            selectedStudent: null,

            showEditClassroomModal: false,
            showCreateClassroomModal: false,

            showDeleteClassroomModal: false,
            isDeletingClassroom: false,

            selectedClassroom: null,
            familyGroup: {
                id: null,
                uuid: v4(),
                title: this.$t("students.myFamily")
            }
        }
    },
    watch: {
        classrooms() {
            //auto select first created class
            this.$nextTick(() => {
                if (!this.selectedClassroom && this.classrooms.length > 0) {
                    this.selectedClassroom = this.classrooms[0]
                }
            })
        }
    },
    async created() {
        if (this.$store.state.auth.user.isTeacher) {
            this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS) //to show under classroom title

            await this.$store.dispatch(types.USER_DATA.GET_CLASSROOMS)

            //select first
            if (this.classrooms.length > 0) {
                this.selectClassroom(this.classrooms[0])
            }
        }

        //select first
        if (this.$store.state.auth.user.isParent) {
            //dont use GET_STUDENTS_BY_CLASSROOM_UUID, just fetch all students
            this.selectedClassroom = this.familyGroup

            this.$store.dispatch(types.USER_DATA.GET_STUDENTS)
        }

        if (this.$route.query.classroomUuid) {
            this.$nextTick(() => {
                let classroom = this.classrooms.find(
                    (c) => c.uuid.toString() === this.$route.query.classroomUuid.toString()
                )

                if (classroom) {
                    this.editClassroom(classroom)
                }
                this.$router.replace({ query: null })
            })
        }
    },
    computed: {
        classrooms() {
            if (this.$store.state.auth.user.isParent) {
                return [this.familyGroup]
            }
            return this.$store.state.userData.classrooms
        },
        students() {
            if (this.$store.state.auth.user.isParent) {
                return this.$store.state.userData.students
            }
            if (!this.selectedClassroom) {
                return []
            }

            if (
                typeof this.$store.state.userData.studentIdsByClassroomUuid[this.selectedClassroom.uuid] === "undefined"
            ) {
                return []
            }
            let studentIds = this.$store.state.userData.studentIdsByClassroomUuid[this.selectedClassroom.uuid]

            return studentIds.reduce((acc, id) => {
                let student = this.$store.state.userData.students.find((s) => s.id === id)
                if (student) acc.push(student)
                return acc
            }, [])
        }
    },
    methods: {
        onCreatedClassroom() {
            if (!this.selectedClassroom && this.classrooms.length > 0) {
                this.selectClassroom(this.classrooms[0])
            }
        },
        selectClassroom(classroom) {
            this.selectedClassroom = classroom

            //dont load students if family group
            if (classroom.uuid === this.familyGroup.uuid) return

            this.$store.dispatch(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, classroom.uuid)
        },
        deleteClassroom(classroom) {
            this.selectClassroom(classroom)
            this.showDeleteClassroomModal = true
        },
        async confirmDeleteClassroom() {
            this.isDeletingClassroom = true
            await this.$store.dispatch(types.USER_DATA.DELETE_CLASSROOM, this.selectedClassroom)
            this.showDeleteClassroomModal = false
            this.isDeletingClassroom = false
            this.selectedClassroom = null
            this.selectedStudent = null ///make sure this is null

            //select first classroom if possible
            if (this.classrooms.length > 0) {
                this.selectClassroom(this.classrooms[0])
            }
        },

        editClassroom(classroom) {
            this.selectClassroom(classroom)
            this.showEditClassroomModal = true
        },
        createClassroom() {
            this.showCreateClassroomModal = true
        },
        deleteStudent(student) {
            this.selectedStudent = student
            this.showDeleteStudentModal = true
        },
        async confirmDeleteStudent() {
            this.isDeletingStudent = true
            await this.$store.dispatch(types.USER_DATA.DELETE_STUDENT, this.selectedStudent)
            this.showDeleteStudentModal = false
            this.isDeletingStudent = false
            this.selectedStudent = null
        },
        createStudent() {
            this.showCreateStudentModal = true
        },
        editStudent(student) {
            this.selectedStudent = student
            this.showEditStudentModal = true
        },
        async showBulkDocument() {
            this.showClassroomBulkDocumentModal = true
        },
        goToClassroomPage(classroom) {
            this.$router.push(`/user/classrooms/${classroom.uuid}`)
        },
        goToStudentPage(student) {
            this.$router.push(`/user/students/${student.uuid}`)
        },
        getListGroupName(classroom) {
            if (!classroom || !classroom.listGroupId) return null
            let listGroup = this.$store.state.userData.listGroups.find((lG) => lG.id === classroom.listGroupId)
            if (!listGroup) return null

            return listGroup.title
        }
    }
}
</script>
