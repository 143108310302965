<template>
    <Card v-if="wordGroup && game">
        <div class="flex flex-row justify-between items-center p-3">
            <div class="flex flex-row justify-start items-center space-x-3 flex-1 w-auto md:flex-auto md:w-1/3">
                <GoBackButton @click.prevent="$emit('goBack')" />
                <div class="hidden xs:block">
                    <Image
                        class="h-20 w-28 min-w-28 md:w-40 md:min-w-40"
                        :image-url="wordGroup.imageUrl"
                        :alt="wordGroup.text" />
                </div>
                <div>
                    <h5 class="text-purple opacity-60 uppercase">
                        {{ $t(`global.gameTypes.${game.gameType.slug}`) }}
                    </h5>
                    <h2
                        class="pt-2"
                        v-if="traduction">
                        {{ capitalize(traduction.text) }}
                    </h2>
                    <h2
                        class="pt-2"
                        v-else-if="wordGroup">
                        {{ capitalize(wordGroup.text) }}
                    </h2>
                    <h6
                        class="text-purple font-medium pt-2 opacity-60 uppercase"
                        v-if="subtitle">
                        {{subtitle}}
                    </h6>
                </div>
            </div>
            <h4
                class="text-purple-40 uppercase hidden md:block"
                v-if="!gameData.isFinish && !gameData.isStarted && $store.getters.isStudentLogin">
                {{ $t("game.waitingPlayers") }}
            </h4>
            <div
                class="flex flex-row justify-end items-center space-x-3 w-1/3"
                v-if="game && gameData">
                <div
                    class="flex flex-col"
                    v-if="!gameData.isFinish">
                    <h3 class="text-center text-purple-40 font-bold uppercase">{{ $t("game.code") }}:</h3>
                    <h1 class="text-center pt-1 whitespace-nowrap">
                        {{ cleanCode }}
                    </h1>
                </div>

                <CustomButton
                    @click.prevent="$emit('goBack')"
                    v-if="gameData.isFinish">
                    {{ $t("game.btnFinish") }}
                </CustomButton>

                <CustomButton
                    @click.prevent="$emit('restart')"
                    v-if="$store.getters.isUserLogin && gameData.isFinish">
                    {{ $t("game.btnReplay") }}
                </CustomButton>

                <CustomButton
                    @click.prevent="$emit('terminateGame')"
                    v-if="$store.getters.isUserLogin && !gameData.isFinish && gameData.isStarted">
                    {{ $t("game.btnTerminateGame") }}
                </CustomButton>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "components/cards/Card"
import Image from "components/globals/Image"
import GoBackButton from "components/globals/GoBackButton"
import { cleanGameCode } from "helpers/helpers"
import CustomButton from "components/globals/CustomButton"
import { GAME_TYPES } from "@globals/constants"

export default {
    name: "LiveGameHeader",
    components: { CustomButton, GoBackButton, Image, Card },
    emits: ["goBack", "terminateGame", "restart"],
    props: {
        wordGroup: Object,
        gameData: Object,
        game: Object
    },
    data(){
        return {
            GAME_TYPES
        }
    },
    computed: {
        subtitle(){
            if (!this.gameData) return null
            if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME) {
                if (this.gameData.extraData.type === 'time') {
                    return `${this.$t("game.endGame")}: ${this.gameData.extraData.min} minutes`
                }
                if (this.gameData.extraData.type === 'money') {
                    return `${this.$t("game.endGame")}: ${this.gameData.extraData.money}$`
                }
            }
            return `${this.gameData.sectionsCount} ${this.$t("global.questions")}`

        },
        traduction() {
            if (!this.wordGroup || !this.game) return null
            return this.wordGroup.traductions.find((t) => t.lang === this.game.lang)
        },
        cleanCode() {
            if (!this.game) return null
            return cleanGameCode(this.game.code)
        }
    }
}
</script>
